const translation = {
  translation: {
    start: {
      changeAccount: "Byt konto",
      chooseAccount: "Välj konto",
      contactSupplierForSupport: "Kontakta din leverantör för support.",
      createNewFacility: "Skapa ny anläggning",
      lastChanged: "Senast ändrat",
      logOut: "Logga ut",
      nameMissing: "Namn saknas",
      noUnitsFound: "Inga enheter hittades",
      permissionDenied: "Behörighet saknas",
      searchExistingFacility: "Sök efter befintlig anläggning",
      searchResults: "Sökresultat",
      user: "Användare",
      title: "Easyserv installationsportal<br/>installation av SG-A & SG-T",
      account: "Konto",
    },
    guideDoc: {
      startInstallation: "Börja installera",
      nextStep: "Nästa steg",
      introduction: "Inledning",
      hardwareRemoval: "Demontering för installation",
      hardwareInstallation: "Installation hårdvara",
      postInstallationReset: "Återställning efter installation",
      previous: "Föregående",
    },
    home: {
      install: "Installera",
      installerLogin:
        "Som installatör loggar du in dig här <br/>för att lägga till och hantera anläggningar.",
      settings: "Inställningar",
      pairDevice:
        "Anslut enheten via Bluetooth <br/>för att göra inställningar på plats.",
      pair: "Anslut",
      login: "Logga in",
    },
    install: {
      supportTitle: "Support",
      supportDescription:
        "<p>Behöver du hjälp med installationen<br/> kan du kontakta oss.</p><p>Öppettider: 08.00 - 16.00 (vardagar)<br/>Tel: 035-710 11 50<br/>Mail: support@easyserv.se</p>",
      mountainLandLake: "Berg/jord/sjö",
      airWater: "Luft/vatten",
      exhaustAir: "Frånluft",
      unspecifiedType: "Ospecifierad typ",
      account: "Konto",
      address: "Adress",
      addTempSensor: "Lägg till tempsensor",
      areaNotFound:
        "Elområden kunde inte hittas.<br/>Kontrollera Postnummer och adressen du har angett.",
      asset: "Anläggning",
      assetId: "Anläggnings-id",
      assetIdEan: "Anläggnings-id / EAN",
      assetIdMissing: "Anläggnings-id saknas",
      back: "Tillbaka",
      busyHardwareId: "Hårdvaru-ID är upptaget",
      cancel: "Avbryt",
      change: "Ändra",
      checkboxError:
        "Checkboxen måste vara ikryssad<br/> eller annat fel uppstod.",
      city: "Ort",
      confirm: "Bekräfta",
      confirmCorrect: "Jag bekräftar att alla uppgifter stämmer",
      confirmDelete:
        "<strong>Är du säker att du vill ta bort tempsensorn?</strong><br/>ID: {{tempSensorId}}",
      confirmExit: "Är du säker att du vill avsluta?",
      confirmExternalInputs: "Bekräfta externingångar",
      confirmInstallation: "Bekräfta installation",
      connectedCables: "Anslutna externingångar",
      connectHardware: "Koppla hårdvara",
      connectingSensor: "Kopplar upp tempsensorn <br/>var god vänta",
      connectingSGA: "Kopplar upp SG-A<br/>Var god vänta",
      continue: "Gå vidare",
      continueWithInstallation: "Gå vidare med installationen",
      createAsset: "Skapa anläggning",
      done: "Klart!",
      electricityArea: "Elområde",
      enterAddress: "Ange adress till anläggningen",
      enterHardwareID: "Ange hårdvaru-ID",
      enterID: "Vid problem att scanna, knappa in ID (18 tecken)",
      enterSensorId: "Ange sensor-ID",
      error: "Fel!",
      errorOccurred: "Fel uppstod!",
      externalInputNote:
        "<strong>OBS!</strong> <br/> På vissa värmepumpar kan en extern utgång vara upptagen eller saknas, vilket gör denna kontroll viktig. <br /><br />Om endast en extern ingång används, anslut alltid gul/brun ledare och lämna vit/rosa ledare oansluten.<br /><br />Notera att extern ingång kan kallas ”AUX” eller ”EXT”.",
      finish: "Avsluta",
      finishAndSave: "Slutför och spara",
      getPos: "Hämta position",
      gridArea: "Nätområde",
      guide: "Guide",
      hardwareId: "Hårdvaru-ID",
      hardwareSGA: "Hårdvara SG-A",
      heatPump: "Värmepump",
      howManyExternalInputs: "Bekräfta nedan den inkoppling du nu gjort på värmepumpen:",
      howManyExternalInputsOutdoor: "Utegivare (A-B)",
      howManyExternalInputsEVU: "Utegivare samt en externingång (A-B-C)",
      howManyExternalInputsSGR: "Utegivare samt två externingångar (A-B-C-D)",
      installation: "Installation",
      installationCheck: "Kontroll installation",
      installationComplete: "Installationen är nu klar!",
      installer: "Installatör",
      installWithoutSGT: "Installera utan SG-T",
      invalidAssetInput: "Fel inmatning Anläggnings-id / EAN",
      manufacturer: "Tillverkare",
      mapOverlay: "(Kartöverskikt, kontrollera att det stämmer)",
      model: "Modell",
      multipleFailures:
        "Om uppkoppling misslyckas flertal gånger <br/>kan du vidare och slutföra installationen.",
      nextStep: "Nästa steg",
      no: "Nej",
      noSensor: "Ingen sensor",
      pairingFailed: "Parkoppligen <br/>misslyckades!",
      pairingFailedTroubleshoot:
        "Kontrollera att tempsensorn har batteri i sig <br/>och att du har Internet. <br/>Försök igen minst 2 gr innan du går vidare.",
      pairingInstructions1:
        "Tryck in knapp 2-3 ggr, se bild <br/>OBS! Tryck korta tryck, håll ej inne knapp",
      pairingInstructions2:
        "Invänta bekräftelse, tar 10-20 sek",
      pairTempSensor: "Parkoppla tempsensor <br/>SG-T",
      postalCode: "Postnr",
      powerCycleSGA:
        "Prova också att dra ut strömmen från SG-A <br/>och sätt in den igen och vänta tills den slutar att blinka och försök igen.",
      prepareInstallation: "Förbered installation",
      proceedWithoutSGT:
        "Om SG-T saknas eller finns redan inlagd kan du gå till nästa steg.",
      remove: "Ta bort",
      scanQRCodeToAddSensor:
        '<strong>Scanna SG-T QR-kod på baksidan för att lägga till sensor.</strong><br/><i class="text-grey">Vid problem att scanna, knappa in ID (18 tecken)</i>',
      scanQRCodeToConnect: "Scanna SG-A QR-kod på undersidan för att ansluta.",
      scanProblem: "Vid problem att scanna: prova med mer ljus, vinkla mobilen för bättre skärpa.",
      search: "Sök",
      searchHeatPumpModel: "Sök värmepumpsmodell här...",
      selectHeatPump: "Välj värmepump",
      sensor: "Sensor",
      sensorConnected:
        "Tempsensor är nu uppkopplad <br/>och du kan nu gå vidare.",
      sensorMissing: "Sensor saknas!",
      sgaConnected: "SG-A är nu uppkopplad<br/>och du kan nu gå vidare.",
      sgaConnectionFailed: "SG-A uppkoppling <br/>misslyckades!",
      SGARestarts: "SG-A startar nu om, tar ca 15-30 sek",
      SGATroubleshoot:
        'Se till att SG-A enheten har 4G-täckning <br/> och att den lyser Grönt <span class="color-green text-big bold">&#9473;</span>&nbsp; eller Orange<span class="color-orange text-big bold">&#9473;</span>. <br/>Prova också att dra ut strömmen från SG-A <br/>och sätt in den igen och vänta tills den slutar att blinka och försök igen.',
      size: "Storlek",
      skipGuide: "Hoppa över guide",
      stepDescription:
        "I detta steg ansluts SG-A hårdvara till Easyserv styrdatabas som ger pauskommandon till värmepump",
      stepSGTDescription: "I detta steg ansluts SG-T till SG-A och börjar nu sända klimatdata till styrdatabas och app.",
      streetAddress: "Gatuadress",
      success: "Lyckades",
      support: "Support",
      techError: "Internet eller teknisk fel. Försök igen",
      tempSensorAdded: "Inlagd tempsensor",
      tempSensorSGT: "Temp-sensor SG-T",
      test4GCoverage: "Test 4G-täckning",
      tryAgain: "Försök igen",
      type: "Typ",
      undo: "Ångra",
      verifyDetails:
        'Kontrollera att uppgifterna nedan <br/>är korrekta och tryck sedan på "Bekräfta"',
      writeAssetId:
        "Skriv in de 12 sista siffrorna i kundens<br/>Anläggnings-id / EAN.",
      yes: "Ja",
    },
    guideInfo: {
      checkAvailability: "Kontrollera att följande saker finns tillgängliga",
      heatPumpUnit: "SG-A, enhet som ansluts till värmepump",
      sgTHouseTempSensor: "SG-T, tempsensor som placeras mitt i huset",
      powerAdapterToSGA: "Spänningsadapter till SG-A",
      cableConnectingSgaHeatPump: "Kablage som kopplas mellan SG-A - värmepump",
      checkPowerOutlet:
        "Kontrollera att ett 230V vägguttag finns i närheten, annars dras förlängningskabel från närmaste vägguttag",
      switchOnSGA:
        'Ställ switch på SG-A i läge ”On"',
      insertBatteriesSensor:
        "För att aktivera temperatursensorn, dra ut Pull-Tab. Sensorn är nu redo att installeras och börja mäta temperatur och luftfuktighet.",
      placeUnitConnectPower:
        "Placera enheten där den kommer befinna sig när den är färdiginstallerad. Koppla ström-adaptern till SG-A och vänta tills lysdioden lyser eller blinkar med GRÖNT sken, tar 10-30 sek.",
      check4GCoverage:
        "Kontrollera 4G-täckning genom att trycka 4G-knapp i hål (se bild, använd t.ex. ett gem):",
      greenGoodCoverage:
        "Grönt: <strong>Bra täckning</strong>, gå vidare till nästa steg",
      orangeSufficientCoverage:
        "Orange: <strong>Bör fungera</strong>, gå vidare till nästa steg",
      redInsufficientCoverage:
        "Röd: <strong>Ej tillräckligt bra täckning</strong> för stabil funktion",
      redLedRelocate:
        "Vid röd LED, prova att flytta enheten till en annan position. Undvik att lägga den bakom t ex skyddsplåtar eller andra metalliska föremål. Du kan också prova en annan vinkel på montaget (t ex stående istället för liggande). Testa en ny position och på knappen enligt bild ovan. Även om den blir orange kan ovan testas för att få grön markering",
      verifyInstallation:
        "Nu är det dags att kontrollera och verifiera installation, enligt följande:",
      sgAMustBeConnected:
        'SG-A ska vara inkopplad och i drift med <span class="color-green text-big">&#9473;</span> grön lysdiod. Om den lyser orange <span class="color-orange text-big">&#9473;</span>, sätt strömbrytaren i läge "on"',
      verifyOutdoorTemp:
        "Kontrollera att värmepumpens utomhustemperatur stämmer överens med den faktiska temperaturen. Om värdet är felaktigt eller larm visas, kontrollera kabelanslutningarna till utomhustemperatursensorn och åtgärda eventuella problem.",
      doFollowing: "Gör följande:",
      simulateOutdoorTemperature:
        "- Tryck och håll inne den blå knappen i tre sekunder, och utegivartemperatur simuleras på värmepump i tre olika steg med 15 sek intervall. Först visas +10 grader, sedan 0 grader och slutligen -10 grader.",
      incorrectValuesSwapLeads:
        'Om värdena är helt felaktiga, byt plats på röd/grå ledare och prova igen. Blinkar lysdiod orange, så är ej switch på SG-A i läge ”On" ',
      externalInputsActivation:
        "Testa simulering av externingångar. <br /><br />Fortsätt klicka på blå knapp för resp. externingång.<br /><br />Kontrollera på värmepumpens display att externingång aktiveras vid varje tryck.",
      attention: "OBS!",
      importantTurnOffMainSwitch: "Viktigt att slå av huvudströmbrytare",
      heatPumpBeforeInstall:
        "för värmepump innan installationen påbörjas.<br/> (om huvudströmbrytare ej finns - slå ifrån säkringar)",
      understood: "Uppfattat",
      noGuide: "Guide saknas",
      installationGuideMissing:
        "Installationsguide saknas för värmepumpsmodellen <strong>{{model}}</strong>.<br/> Kontakta oss om du behöver hjälp med installationen.<br />",
      disassembleForInstall: "Demontering för installation",
      installHardware: "Installation hårdvara",
      postInstallReset: "Återställning efter installation",
      deployment: "Driftsättning",
      testSimulateOutdoorTemp: "Testa simulering av utomhustemperatur",
      testSimulateOutdoorTempA: "Tryck och håll den blå knappen intryckt i 3 sekunder - när du släpper ska ca. +10 °C visas som utetemperatur.",
      testSimulateOutdoorTempB: "Tryck kort på den blå knappen och 0 °C ska visas som utetemperatur.",
      testSimulateOutdoorTempC: "Tryck kort på den blå knappen igen och -10 °C ska visas som utetemperatur.",
      testSimulateOutdoorTempWrong: "Om värdena är helt felaktiga, byt plats på röd/grå ledare och testa igen. Blinkar lysdiod orange, sätt switchen på SG-A i läge ”On”",
    },
  },
};

export default translation;