const translation = {
  translation: {
    start: {
      changeAccount: "Vaihda tili",
      chooseAccount: "Valitse tili",
      contactSupplierForSupport: "Ota yhteyttä toimittajaan saadaksesi tukea.",
      createNewFacility: "Luo uusi laitteistö",
      lastChanged: "Viimeksi muutettu",
      logOut: "Kirjaudu ulos",
      nameMissing: "Nimi puuttuu",
      noUnitsFound: "Yksiköitä ei löytynyt",
      permissionDenied: "Lupa evätty",
      searchExistingFacility: "Etsi olemassa oleva laitteisto",
      searchResults: "Etsi tulokset",
      user: "Käyttäjä",
      title: "Easyserv-asennusportaali<br/>SG-A & SG-T asennus",
      account: "Tili",
    },
    guideDoc: {
      startInstallation: "Aloita asennus",
      nextStep: "Seuraava vaihe",
      introduction: "Johdanto",
      hardwareRemoval: "Purkuvaihe",
      hardwareInstallation: "Laitteiston asennus",
      postInstallationReset: "Nollaus asennuksen jälkeen",
      previous: "Edellinen",
    },
    home: {
      install: "Asenna",
      installerLogin:
        "Asentajana kirjaudut sisään tästä <br/>lisätäksesi ja hallitaksesi laitoksia.",
      settings: "Asetukset",
      pairDevice:
        "Liitä laite Bluetoothin kautta <br/>tehdäksesi asetuksia paikan päällä.",
      pair: "Yhdistä",
      login: "Kirjaudu sisään",
    },
    install: {
      supportTitle: "Tuki",
      supportDescription:
        "<p>Tarvitsetko apua asennuksessa?<br/> Voit ottaa meihin yhteyttä.</p><p>Aukioloajat: 08.00 - 16.00 (arkipäivät)<br/>Puh: 035-710 11 50<br/>Sähköposti: support@easyserv.se</p>",
      mountainLandLake: "Vuori/maa/järvi",
      airWater: "Ilma/vesi",
      exhaustAir: "Poistoilma",
      unspecifiedType: "Tyyppi määrittelemätön",
      account: "Tili",
      address: "Osoite",
      addTempSensor: "Lisää lämpötila-anturi",
      areaNotFound:
        "Sähköalueita ei löytynyt.<br/>Tarkista postinumero ja antamasi osoite.",
      asset: "Laitos",
      assetId: "Laitoksen tunnus",
      assetIdEan: "Laitoksen tunnus / EAN",
      assetIdMissing: "Laitoksen tunnus puuttuu",
      back: "Takaisin",
      busyHardwareId: "Laitetunnus on varattu",
      cancel: "Peruuta",
      change: "Muuta",
      checkboxError:
        "Valintaruutu on valittava<br/> tai muu virhe on tapahtunut.",
      city: "Kaupunki",
      confirm: "Vahvista",
      confirmCorrect: "Vahvistan, että kaikki tiedot ovat oikein",
      confirmDelete:
        "<strong>Haluatko varmasti poistaa lämpötila-anturin?</strong><br/>Tunnus: {{tempSensorId}}",
      confirmExit: "Haluatko varmasti poistua?",
      confirmExternalInputs: "Vahvista ulkoiset sisääntulot",
      confirmInstallation: "Vahvista asennus",
      connectedCables: "Liitetyt ulkoiset sisääntulot",
      connectHardware: "Yhdistä laitteisto",
      connectingSensor: "Yhdistetään lämpötila-anturia<br/> odota hetki",
      connectingSGA: "Yhdistetään SG-A<br/> Odota hetki",
      continue: "Jatka",
      continueWithInstallation: "Jatka asennusta",
      createAsset: "Luo laitos",
      done: "Valmis!",
      electricityArea: "Sähköalue",
      enterAddress: "Syötä laitoksen osoite",
      enterHardwareID: "Syötä laitetunnus",
      enterID: "Jos skannaamisessa on ongelmia, syötä tunnus (18 merkkiä)",
      enterSensorId: "Syötä anturin tunnus",
      error: "Virhe!",
      errorOccurred: "Tapahtui virhe!",
      externalInputNote:
        '<strong>HUOM!</strong> <br/> Joissakin lämpöpumpuissa ulkoinen lähtö voi olla varattu tai puuttua kokonaan, mikä tekee tästä tarkistuksesta tärkeän.<br/> Jos käytössä on vain yksi ulkoinen sisääntulo, kytke aina keltaruskea johdin ja jätä valkovaaleanpunainen johdin kytkemättä.<br/> Huomaa, että ulkoinen sisääntulo voi olla merkitty nimillä "AUX" tai "EXT"',
      finish: "Lopeta",
      finishAndSave: "Valmista ja tallenna",
      getPos: "Hae sijainti",
      gridArea: "Verkkoalue",
      guide: "Opas",
      hardwareId: "Laitetunnus",
      hardwareSGA: "Laitteisto SG-A",
      heatPump: "Lämpöpumppu",
      howManyExternalInputs: "Vahvista tekemäsi kytkentä lämpöpumppuun alla:",
      howManyExternalInputsOutdoor: "Ulkoyksikkö (A-B)",
      howManyExternalInputsEVU: "Ulkoyksikkö ja ulkoinen sisääntulo (A-B-C)",
      howManyExternalInputsSGR: "Ulkoyksikkö ja kaksi ulkoista sisääntuloa (A-B-C-D)",
      installation: "Asennus",
      installationCheck: "Asennuksen tarkistus",
      installationComplete: "Asennus on valmis!",
      installer: "Asentaja",
      installWithoutSGT: "Asenna ilman SG-T:tä",
      invalidAssetInput: "Virheellinen laitoksen tunnus/EAN",
      manufacturer: "Valmistaja",
      mapOverlay: "(Karttapeite, varmista että se on oikein)",
      model: "Malli",
      multipleFailures:
        "Jos yhteys epäonnistuu useita kertoja,<br/>voit siirtyä ja suorittaa asennuksen loppuun.",
      nextStep: "Seuraava vaihe",
      no: "Ei",
      noSensor: "Ei anturia",
      pairingFailed: "Pariliitos epäonnistui!",
      pairingFailedTroubleshoot:
        "Tarkista, että lämpötila-anturissa on paristot<br/> ja että sinulla on internet-yhteys. <br/>Yritä uudelleen vähintään 2 kertaa ennen kuin jatkat.",
      pairingInstructions1:
        "Paina nappia 2-3 kertaa, katso kuva<br/> HUOM! Paina nopeasti, älä pidä nappia pohjassa<br/>",
      pairingInstructions2: "Odota vahvistusta, kestää 10-20 sekuntia",
      pairTempSensor: "Pariliitä lämpötila-anturi<br/> SG-T",
      postalCode: "Postinumero",
      powerCycleSGA:
        "Kokeile myös irrottaa SG-A:sta virta<br/> ja kytkeä se uudelleen ja odota, kunnes vilkkuminen loppuu, ja yritä uudelleen.",
      prepareInstallation: "Valmistele asennus",
      proceedWithoutSGT:
        "Jos SG-T puuttuu tai on jo asennettu, voit siirtyä seuraavaan vaiheeseen.",
      remove: "Poista",
      scanQRCodeToAddSensor:
        '<strong>Skannaa SG-T:n QR-koodi takaa lisätäksesi anturin.</strong><br/><i class="text-grey">Jos skannaamisessa on ongelmia, syötä tunnus (18 merkkiä)</i>',
      scanQRCodeToConnect: "Skannaa SG-A:n QR-koodi pohjasta yhdistääksesi.",
      scanProblem: "Jos skannauksessa ilmenee ongelmia: kokeile lisää valoa ja kallista puhelinta paremman tarkkuuden saamiseksi.",
      search: "Etsi",
      searchHeatPumpModel: "Etsi lämpöpumpun mallia täältä...",
      selectHeatPump: "Valitse lämpöpumppu",
      sensor: "Anturi",
      sensorConnected:
        "Lämpötila-anturi on nyt yhdistetty<br/>ja voit jatkaa eteenpäin.",
      sensorMissing: "Anturi puuttuu!",
      sgaConnected: "SG-A on nyt yhdistetty<br/>ja voit jatkaa eteenpäin.",
      sgaConnectionFailed: "SG-A:n yhdistäminen epäonnistui!",
      SGARestarts: "SG-A käynnistyy nyt uudelleen, kestää noin 15-30 sekuntia",
      SGATroubleshoot:
        'Varmista, että SG-A:lla on 4G-peittoa <br/> ja että se palaa Vihreänä <span class="color-green text-big bold">&#9473;</span>&nbsp; tai Oranssina <span class="color-orange text-big bold">&#9473;</span>. <br/>Kokeile myös irrottaa virta SG-A:sta <br/>ja kytkeä se uudelleen ja odota vilkkumisen loppumista ja yritä uudelleen.',
      size: "Koko",
      skipGuide: "Ohita opas",
      stepDescription:
        "Tässä vaiheessa SG-A laite yhdistetään ohjaustietokantaan, joka antaa taukokäskyjä lämpöpumpulle.",
      stepSGTDescription: "Tässä vaiheessa SG-T liitetään SG-A:han ja se alkaa nyt lähettämään ilmastotietoja ohjaustietokantaan ja sovellukseen.",
      streetAddress: "Katuosoite",
      success: "Onnistui",
      support: "Tuki",
      techError: "Internet- tai tekninen virhe. Yritä uudelleen",
      tempSensorAdded: "Lämpötila-anturi lisätty",
      tempSensorSGT: "Lämpötila-anturi SG-T",
      test4GCoverage: "Testaa 4G-peittoalue",
      tryAgain: "Yritä uudelleen",
      type: "Tyyppi",
      undo: "Kumoa",
      verifyDetails:
        'Varmista, että alla olevat tiedot ovat oikein<br/> ja paina sitten "Vahvista"',
      writeAssetId:
        "Kirjoita asiakkaan<br/>laitostunnuksen/EAN:n 12 viimeistä numeroa.",
      yes: "Kyllä",
    },
    guideInfo: {
      checkAvailability: "Varmista, että seuraavat asiat ovat saatavilla",
      heatPumpUnit: "SG-A, yksikkö joka kytketään lämpöpumppuun",
      sgTHouseTempSensor:
        "SG-T, lämpötila-anturi, joka sijoitetaan talon keskelle",
      powerAdapterToSGA: "Virtasovitin SG-A:lle",
      cableConnectingSgaHeatPump: "Kaapeli SG-A:n ja lämpöpumpun välillä",
      checkPowerOutlet:
        "Varmista, että lähistöllä on 230V pistorasia, muuten vedä jatkojohto lähimmästä pistorasiasta",
      switchOnSGA:
        'Aseta SG-A:n kytkin asentoon "On"',
      insertBatteriesSensor:
        "Aktivoidaksesi lämpötila-anturin, vedä Pull-Tab ulos. Se on nyt valmis asennettavaksi ja mittaamaan lämpötilaa ja ilmankosteutta.",
      placeUnitConnectPower:
        "Aseta yksikkö paikalleen, jossa se on lopullisesti asennettuna. Kytke virtasovitin SG-A:han ja odota, että LED-valo palaa tai vilkkuu VIHREÄNÄ, kestää 10-30 sekuntia.",
      check4GCoverage:
        "Tarkista 4G-verkon kattavuus painamalla 4G-painiketta reiässä (katso kuva, käytä esimerkiksi paperiliitintä):",
      greenGoodCoverage:
        "Vihreä: <strong>Hyvä peittoalue</strong>, siirry seuraavaan vaiheeseen",
      orangeSufficientCoverage:
        "Oranssi: <strong>Pitäisi toimia</strong>, siirry seuraavaan vaiheeseen",
      redInsufficientCoverage:
        "Punainen: <strong>Ei riittävää yhteyttä</strong> vakaaseen toimintaan",
      redLedRelocate:
        "Punaisen LED-valon ollessa päällä, yritä siirtää laitetta toiseen paikkaan. Vältä sijoittamista esim. suojapeltien tai muiden metalliesineiden taakse. Voit myös kokeilla eri asennuskulmaa (esim. pystyasennossa vaakasijaan verrattuna). Testaa uusi sijainti ja paina painiketta kuten yllä olevassa kuvassa. Vaikka väri muuttuu oranssiksi, testiä voi kokeilla uudelleen vihreän merkinnän saamiseksi.",
      verifyInstallation:
        "Nyt on aika tarkistaa ja vahvistaa asennus seuraavasti:",
      sgAMustBeConnected:
        'SG-A:n tulee olla kytketty ja käynnissä, ja <span className="color-green text-big">&#9473;</span> vihreän LED-valon tulee palaa. Jos se palaa oranssina <span class="color-orange text-big">━</span>, aseta virtakytkin asentoon "on"',
      verifyOutdoorTemp:
        "Tarkista, että lämpöpumpun ulkolämpötila vastaa todellista lämpötilaa. Jos arvo on virheellinen tai se näyttää hälytystä, tarkista ulkolämpötila-anturin kaapelikytkennät ja korjaa mahdolliset ongelmat.",
      doFollowing: "Tee seuraavat toimet:",
      simulateOutdoorTemperature:
        "- Paina ja pidä sinistä painiketta alhaalla kolme sekuntia, ja ulkolämpötila simuloituu lämpöpumpussa kolmessa vaiheessa 15 sekunnin välein. Ensin näkyy +10 astetta, sitten 0 astetta ja lopuksi -10 astetta.",
      incorrectValuesSwapLeads:
        'Jos arvot ovat täysin vääriä, vaihda punainen ja harmaa johto keskenään ja kokeile uudelleen. Jos LED-valo vilkkuu oranssina, SG-A:n kytkin ei ole "On"-asennossa.',
      externalInputsActivation:
        "Testaa ulkoisten sisääntulojen simulointi.<br /><br />Jatka sinisen painikkeen painamista kutakin ulkoista sisääntuloa varten.<br /><br />Tarkista lämpöpumpun näytöltä, että ulkoinen sisääntulo aktivoituu joka painalluksella.",
      attention: "HUOM!",
      importantTurnOffMainSwitch: "Tärkeää katkaista päävirtakytkin",
      heatPumpBeforeInstall:
        "lämpöpumpusta ennen asennuksen aloittamista.<br/> (jos päävirtakytkintä ei ole, katkaise sulakkeet)",
      understood: "Ymmärretty",
      noGuide: "Opas puuttuu",
      installationGuideMissing:
        "Lämpöpumppumallista puuttuu asennusohje <strong>{{model}}</strong>.<br/> Ota yhteyttä, jos tarvitset apua asennuksessa.<br />",
      disassembleForInstall: "Purkaminen asennusta varten",
      installHardware: "Laitteiston asennus",
      postInstallReset: "Kasaus asennuksen jälkeen",
      deployment: "Käyttöönotto",
      testSimulateOutdoorTemp: "Testaa ulkolämpötilan simulointi.",
      testSimulateOutdoorTempA: "Paina ja pidä sinistä painiketta pohjassa 3 sekunnin ajan – kun vapautat sen, pitäisi ulkolämpötilan näyttää noin +10 °C.",
      testSimulateOutdoorTempB: "Paina lyhyesti sinistä painiketta, ja ulkolämpötilan pitäisi näyttää 0 °C.",
      testSimulateOutdoorTempC: "Paina lyhyesti sinistä painiketta uudelleen ja ulkolämpötilan pitäisi näyttää -10 °C.",
      testSimulateOutdoorTempWrong: 'Jos arvot ovat täysin virheellisiä, vaihda punaisen/harmaan johdon paikka ja testaa uudelleen. Jos LED-valo vilkkuu oranssina, aseta SG-A:n kytkin asentoon "On".',
    },
  },
};

export default translation;
